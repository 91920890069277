var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "annex" }, [
    _c("span", { staticClass: "label" }, [_vm._v("附件")]),
    _c(
      "ul",
      _vm._l(_vm.aftersaleAttachList, function(item, index) {
        return _c(
          "li",
          { key: index, staticClass: "item" },
          [
            _c("el-image", {
              staticStyle: { width: "130px", height: "130px" },
              attrs: {
                src: item.fileUrl,
                "preview-src-list": _vm.srcList,
                fit: "contain"
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }