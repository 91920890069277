<template>
  <div class="annex">
    <span class="label">附件</span>
    <ul>
      <li class="item" v-for="(item, index) in aftersaleAttachList" :key="index">
        <el-image 
          style="width: 130px; height: 130px"
          :src="item.fileUrl" 
          :preview-src-list="srcList"
          fit="contain">
        </el-image>
      </li>
    </ul>
  </div> 
</template>

<script>
export default {
  props: {
    aftersaleAttachList: Array,
  },
  computed: {
    srcList(){
      let srcList = []
      this.aftersaleAttachList.map(item => srcList.push(item.fileUrl))
      return srcList
    }
  }
}
</script>

<style lang="scss" scoped>
.annex{
  position: relative;
  border: 1px solid #F0F0F3;
  height: 166px;
  padding: 18px;
  box-sizing: border-box;
  margin-top: 12px;
  .label{
    position: absolute;
    background-color: #fff;
    color: #999;
    font-size: 12px;
    left: 12px;
    top: 0;
    transform: translateY(-50%);
  }
  ul{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    li{
      flex-shrink: 0;
      width: 130px;
      height: 130px;
      border: 1px solid #707070;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
      }
    }
  }
}
</style>